import React, {useState, useEffect, Suspense, lazy} from "react";
import "./Project.scss";
import Button from "../../components/button/Button";
import {openSource, socialMediaLinks} from "../../portfolio";
// import StyleContext from "../../contexts/StyleContext";
import Loading from "../../containers/loading/Loading";
export default function Projects() {
  const GithubRepoCard = lazy(() =>
    import("../../components/githubRepoCard/GithubRepoCard")
  );
  const FailedLoading = () => null;
  const renderLoader = () => <Loading />;
  const [repo, setrepo] = useState([]);
  // todo: remove useContex because is not supported

  const userData = {
    user: {
      name: "User's name",
      bio: "User's bio",
      avatarUrl: "URL to user's avatar",
      location: "User's location",
      pinnedItems: {
        totalCount: 6,
        edges: [
          {
            node: {
              name: "Vignan Placement Application",
              description: "All placements activities are monitored, handled and informed by this application",
              forkCount: 1,
              stargazers: {
                totalCount: 1
              },
              url: "https://github.com/chari6268",
              id: "Repository ID",
              diskUsage: 0,
              primaryLanguage: {
                name: "java",
                color: "#b07219"
              }
            }
          },
          {
            node: {
              name: "Syllabbus App",
              description: " This Application collects all the information about the student who are willing to get more percentage in end Sem Exams",
              forkCount: 1,
              stargazers: {
                totalCount: 1
              },
              url: "https://github.com/chari6268",
              id: "Repository ID",
              diskUsage: 0,
              primaryLanguage: {
                name: "Java",
                color: "#b07219"
              }
            }
          },
          {
            node: {
              name: "Sentiment analysis of restaurant reviews App",
              description: " It is used to identify the sentiment of customer reviews, such as whether they are positive, negative, or neutral.",
              forkCount: 1,
              stargazers: {
                totalCount: 1
              },
              url: "https://github.com/chari6268",
              id: "Repository ID",
              diskUsage: 0,
              primaryLanguage: {
                name: "Python",
                color: "#3572A5"
              }
            }
          },
          {
            node: {
              name: "Edu Placements Application",
              description: "All placements activities are monitored, handled and informed by this application",
              forkCount: 1,
              stargazers: {
                totalCount: 1
              },
              url: "https://github.com/chari6268",
              id: "Repository ID",
              diskUsage: 0,
              primaryLanguage: {
                name: "JavaScript",
                color: "#f1e05a"
              }
            }
          },
          {
            node: {
              name: "Diabetes Prediction",
              description: "It describes the development of a deep learning-based mobile application for predicting diabetes.",
              forkCount: 1,
              stargazers: {
                totalCount: 1
              },
              url: "https://github.com/chari6268",
              id: "Repository ID",
              diskUsage: 0,
              primaryLanguage: {
                name: "Jupyter Notebook   ",
                color: "#DA5B0B"
              }
            }
          }
          // More repositories can be added here within the 'edges' array
        ]
      }
    }
  };

  useEffect(() => {
    const getRepoData = () => {
      // fetch("profile.json")
      //   .then(result => {
      //     if (result.ok) {
      //       return result.json();
      //     }
      //     throw result;
      //   })
      //   .then(response => {
      //     setrepoFunction(response.data.user.pinnedItems.edges);
      //   })
      //   .catch(function (error) {
      //     console.error(
      //       `${error} (because of this error, nothing is shown in place of Projects section. Also check if Projects section has been configured)`
      //     );
      //     setrepoFunction("Error");
      //   });
        setrepoFunction(userData.user.pinnedItems.edges);
    };
    getRepoData();
  }, []);

  function setrepoFunction(array) {
    setrepo(array);
  }
  if (
    !(typeof repo === "string" || repo instanceof String) &&
    openSource.display
  ) {
    return (
      <Suspense fallback={renderLoader()}>
        <div className="main" id="opensource">
          <h1 className="project-title"> Projects </h1>
          <div className="repo-cards-div-main">
            {repo.map((v, i) => {
              if (!v) {
                console.error(
                  `Github Object for repository number : ${i} is undefined`
                );
              }
              return (
                <GithubRepoCard repo={v} key={v.node.id}/>
              );
            })}
          </div>
          <Button
            text={"More Projects"}
            className="project-button"
            href={socialMediaLinks.github}
            newTab={true}
          />
        </div>
      </Suspense>
    );
  } else {
    return <FailedLoading />;
  }
}