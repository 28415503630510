import React from "react";
import "./Skills.scss";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import {illustration, skillsSection} from "../../portfolio";
import {Fade} from "react-reveal";
import codingPerson from "../../assets/lottie/codingPerson";
import Person from "../../assets/lottie/person.json";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";

export default function Skills() {
  if (!skillsSection.display) {
    return null;
  }
  return (
    <div className={"main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {
            illustration.animated ? (
              // <DisplayLottie animationData={codingPerson} />
              <DisplayLottie animationData={Person} />
            ) : 
            (
              // <img
              //   alt="Man Working"
              //   // src={require("../../assets/images/developerActivity.svg")}
              //   // src={require("../../assets/lottie/person.json")}
              //   src={"https://media.giphy.com/media/7wA0YhMXvDBhTckOGM/source.gif?cid=790b7611b6565a9092ee51eeee34015858a0783fd77b6f31&rid=source.gif&ct=s"}
              // ></img>
              <DisplayLottie animationData={codingPerson} />
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1
              className={"skills-heading"}
            >
              {skillsSection.title}{" "}
            </h1>
            <p
              className={"subTitle skills-text-subtitle"}
            >
              {skillsSection.subTitle}
            </p>
            <SoftwareSkill />
            <div>
              {skillsSection.skills.map((skills, i) => {
                return (
                  <p
                    key={i}
                    className={"subTitle skills-text"
                    }
                  >
                    {skills}
                  </p>
                );
              })}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}