/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation
// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Srinivasa Chari",
  title: "Hi all, I'm Srinivasa Chari",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / React Native and some other cool libraries and frameworks. I know a little bit of machine learning too !."
  ),
  resumeLink:
    "https://catalog-education.oracle.com/pls/certview/sharebadge?id=21C35E024209F754A93D7253369DC8B9DF5CAC1F0518D54F4FFDA6659504D483", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/chari6268",
  linkedin: "https://www.linkedin.com/in/perusomula-srinivasa-chari-a51816264/",
  gmail: "211fa04389@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "I love all aspects of technology and want to explore as much as I can!",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Deploy and maintain applications on cloud"),
    emoji(
      "⚡ Integration of third party services such as Firebase , Google Spreadsheet,.etc"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    // {
    //   skillName: "C",
    //   fontAwesomeClassname : "devicon-c-plain"
    // },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "GitHub",
      fontAwesomeClassname: "fab fa-github"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Android Studio",
      fontAwesomeClassname: "fab fa-android"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Vignan's Foundation for Science, Technology and Research",
      logo: require("./assets/images/viglogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "2021 - Present",
      desc: "CGPA: 8.30",
      descBullets: []
    }
    // {
    //   schoolName: "Narayana Junior College",
    //   logo: require("./assets/images/harvardLogo.png"),
    //   subHeader: "Intermediate ",
    //   duration: "2019 - 2021",
    //   desc: "CGPA: 9.13",
    //   descBullets: []
    // }
  ]
};



// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Technology Development Intern",
      company: "Vignan University",
      companylogo: require("./assets/images/lang/viglogo.jpg"),
      date: " 2023 – Present ",
      desc: "I am currently working as a technology development intern at Vignan University where I have to develop and maintain university websites and software. Here, I work on backend web development and android app development",
      descBullets: []
    },
    {
      role: "MathWorks Intern",
      company: "MathWorks",
      companylogo: require("./assets/images/lang/mathlab.png"),
      date: "May – Sep 2023",
      desc: "I have worked as Virtual internship at MathWorks in an Get Started with Artificial Intelligence based project.This experience provided me with the opportunity to Develop practical AI solutions using MATLAB.Enhance my understanding of AI applications and tools within the MATLAB environment."
    },
    {
      role: "IBM Skillbuild Intern",
      company: "IBM",
      companylogo: require("./assets/images/lang/ibm-logo-1956.webp"),
      date: "Aug – Oct 2023",
      desc: "I have worked as Virtual internship at IBM Skillbuild in an Artificial Intelligence based project.Collaborate with a virtual team and participate in remote project development.Enhance my understanding of AI applications and tools within the IBM environment."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/lang/Oracle.png"),
      projectName: "NULL",
      projectDesc: "NULL",
      footerLink: [
        {
          name: "Visit Website",
          url: "NULL"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/lang/Oracle.png"),
      projectName: "NULL",
      projectDesc: "NULL",
      footerLink: [
        {
          name: "Visit Website",
          url: "NULL"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: " Preliminary English Test ",
      subtitle: " Certified in PET Examination with Pass with Merit conducted by Cambridge University. ",
      image: require("./assets/images/lang/pet.png"),
      imageAlt: "PET Logo",
      footerLink: [
        {
          name: "Certification",
          url: ""
        }
      ]
    },
    {
      title: " Oracle University ",
      subtitle:
        "Successfully Completing certification in Oracle Cloud Infrastructure 2023 AI Certified Foundations Associate in Oracle University",
      image: require("./assets/images/lang/Oracle.png"),
      imageAlt: "Oracle Logo",
      footerLink: [
        {
          name: "View Oracle Cloud Infrastructure 2023 AI",
          url: "https://catalog-education.oracle.com/pls/certview/sharebadge?id=21C35E024209F754A93D7253369DC8B9DF5CAC1F0518D54F4FFDA6659504D483"
        } 
        // ,{
        //   name: "View Certification",
        //   url: "https://catalog-education.oracle.com/pls/certview/sharebadge?id=74D0EC78640B24BDAFC0608815E0A9BE5BF7DFAE418C6AA0098985DB7D99541F"
        // }
      ]
    },

    {
      title: " IBM ",
      subtitle: "successfully completed with honors Databases and SQL for Data Science with Python an online non-credit course authorized by IBM and offered through Coursera",
      image: require("./assets/images/lang/ibm-logo-1956.webp"),
      imageAlt: "IBM Logo",
      footerLink: [
        {name: "Certification", url: "https://www.coursera.org/account/accomplishments/certificate/X3ZLZS2LBBVA"}
      ]
    },
    // {
    //   title: " Google ",
    //   subtitle: " Successfully Completing certifications in Google Data Analytics in Google Career Certificates",
    //   image: require("./assets/images/lang/google.jpg"),
    //   imageAlt: "Google Logo",
    //   footerLink: [
    //     {name: "Foundations", url: "https://www.coursera.org/account/accomplishments/certificate/PVCD2VS3TEGU"}
    //     // ,{name: "Ask Questions to Make Data-Driven Decisions", url: "https://www.coursera.org/account/accomplishments/certificate/8H47VGT7WLJR"},
    //     // {name: "Prepare Data for Exploration", url: "https://www.coursera.org/account/accomplishments/certificate/TCR892LKFDGQ"},
    //     // {name: "Process Data from Dirty to Clean", url: "https://www.coursera.org/account/accomplishments/certificate/GS7ECJG32PFK"}
    //   ]
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};


// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-8978083533",
  email_address: "211fa04389@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};